import { mapState } from 'vuex'

export default {
    name: 'JsfEdit',
    data: () => ({
        requestedlist: ['SGS', 'Other'],
        requesttypelist: ['New', 'Change', 'Master Update'],
        savetypelist: ['Pending Approval', 'Draft'],
        savetypelistxref: ['Pending', 'Draft'],
        savetype: '',
        storegrouplist: [
            { text: 'Edge', value: 'edge' },
            { text: 'P & E', value: 'pe' },
            { text: 'None', value: 'None' },
            { text: 'Random', value: 'Random' },
        ],
        storegroup: '',
        readyforsave: false,
        showstores: false,
        asknol: false,
        asklock: false,
        dmempty: true,
        menu2: '',
        pickerdate: '',
        linkserver: process.env.VUE_APP_HOSTNAME + '.' + process.env.VUE_APP_SERVER,
        printit: false,
        randomstores: 0,
        editnametitle: '',
        newitemname: '',
        edititem: '',
        edititemmsg: '',
    }),
    async mounted() {
        if (this.$route.params.id) {
            await this.$nextTick(() => {
                this.$store.dispatch('Jsf/fetchJsf', this.$route.params.id, { root: true }).then(() => {
                    if (this.$store.getters['Jsf/getSelectedStores'].length) {
                        /* --- for messaging ---*/
                        this.doorsconfirmed = true

                        /* --- for edit pencil & edit promotion type ---*/
                        this.dmempty = false

                        /* ---
                        ensure string store number is a number store number
                        the initial store list build should be refactored to
                        avoid this 'fix'
                        long-standing bug brought to light in VSTS 25635
                        put in place 2024-11-01  cal
                        --- */
                        const workstores = this.selectedstores
                        this.selectedstores = workstores.map(Number)
                    }

                    if (this.$route.path.split('/')[1] === 'print') {
                        this.printit = true
                    }
                })
            })
        }

        if (this.$store.getters['Sgs/getAdminView']) {
            this.savetypelist.push('Approved')
            this.savetypelistxref.push('Approved')
        }

        this.$store.dispatch('Contact/fetchDmList', null, { root: true })
        this.$store.dispatch('Contact/fetchStoreGroupList', null, { root: true }).then(() => {
            for (let idx = 0; idx < this.sgdblist.length; idx++) {
                this.storegrouplist.push({ text: this.sgdblist[idx].text, value: this.sgdblist[idx].value })
            }
        })
    },
    computed: {
        ...mapState('Sgs', ['user', 'msg', 'msgcolor', 'msgtimeout', 'msgcpos', 'msgtpos']),
        ...mapState('Jsf', ['jsf', 'programlist', 'storelist']),
        showmsg: {
            get() {
                return this.$store.getters['Sgs/getShowMsg']
            },
            set() {
                this.$store.commit('Sgs/RESETMSG')
            },
        },
        selectedstores: {
            get() {
                return this.$store.getters['Jsf/getSelectedStores']
            },
            set(val) {
                val.push('fromform')
                this.$store.commit('Jsf/SET_SELECTEDSTORES', val)
            },
        },
        id: {
            get() {
                return this.$store.getters['Jsf/getId']
            },
            set(val) {
                this.$store.commit('Jsf/SET_ID', val)
            },
        },
        jsfid: {
            get() {
                return this.$store.getters['Jsf/getJsfId']
            },
            set(val) {
                this.$store.commit('Jsf/SET_JSFID', val)
            },
        },
        dep: {
            get() {
                return this.$store.getters['Jsf/getDep']
            },
            set(val) {
                this.$store.commit('Jsf/SET_DEP', val)
            },
        },
        mic: {
            get() {
                return this.$store.getters['Jsf/getMic']
            },
            set(val) {
                this.$store.commit('Jsf/SET_MIC', val)
            },
        },
        requestedfor: {
            get() {
                return this.$store.getters['Jsf/getRequestedFor']
            },
            set(val) {
                this.$store.commit('Jsf/SET_REQUESTEDFOR', val)
            },
        },
        requesttype: {
            get() {
                return this.$store.getters['Jsf/getRequestType']
            },
            set(val) {
                this.$store.commit('Jsf/SET_REQUESTTYPE', val)
            },
        },
        program: {
            get() {
                return this.$store.getters['Jsf/getProgram']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PROGRAM', val)
            },
        },
        created: {
            get() {
                return this.$store.getters['Jsf/getCreated']
            },
            set(val) {
                this.$store.commit('Jsf/SET_CREATED', val)
            },
        },
        lastupdate: {
            get() {
                return this.$store.getters['Jsf/getLastUpdate']
            },
            set(val) {
                this.$store.commit('Jsf/SET_LASTUPDATE', val)
            },
        },
        title: {
            get() {
                return this.$store.getters['Jsf/getTitle']
            },
            set(val) {
                this.$store.commit('Jsf/SET_TITLE', val)
            },
        },
        visualdate: {
            get() {
                return this.$store.getters['Jsf/getVisualDate']
            },
            set(val) {
                this.$store.commit('Jsf/SET_VISUALDATE', val)
            },
        },
        instoredate: {
            get() {
                return this.$store.getters['Jsf/getInStoreDate']
            },
            set(val) {
                this.$store.commit('Jsf/SET_INSTOREDATE', val)
            },
        },
        artdue: {
            get() {
                return this.$store.getters['Jsf/getArtDue']
            },
            set(val) {
                this.$store.commit('Jsf/SET_ARTDUE', val)
            },
        },
        artapproval: {
            get() {
                return this.$store.getters['Jsf/getArtApproval']
            },
            set(val) {
                this.$store.commit('Jsf/SET_ARTAPPROVAL', val)
            },
        },
        shipdate: {
            get() {
                return this.$store.getters['Jsf/getShipDate']
            },
            set(val) {
                this.$store.commit('Jsf/SET_SHIPDATE', val)
            },
        },
        requestedby: {
            get() {
                return this.$store.getters['Jsf/getRequestedBy']
            },
            set(val) {
                this.$store.commit('Jsf/SET_REQUESTEDBY', val)
            },
        },
        area: {
            get() {
                return this.$store.getters['Jsf/getArea']
            },
            set(val) {
                this.$store.commit('Jsf/SET_AREA', val)
            },
        },
        house: {
            get() {
                return this.$store.getters['Jsf/getHouse']
            },
            set(val) {
                this.$store.commit('Jsf/SET_HOUSE', val)
            },
        },
        brand: {
            get() {
                return this.$store.getters['Jsf/getBrand']
            },
            set(val) {
                this.$store.commit('Jsf/SET_BRAND', val)
            },
        },
        buyer: {
            get() {
                return this.$store.getters['Jsf/getBuyer']
            },
            set(val) {
                this.$store.commit('Jsf/SET_BUYER', val)
            },
        },
        vendorcontact: {
            get() {
                return this.$store.getters['Jsf/getVendorContact']
            },
            set(val) {
                this.$store.commit('Jsf/SET_VENDORCONTACT', val)
            },
        },
        vendoremail: {
            get() {
                return this.$store.getters['Jsf/getVendorEmail']
            },
            set(val) {
                this.$store.commit('Jsf/SET_VENDOREMAIL', val)
            },
        },
        vendorphone: {
            get() {
                return this.$store.getters['Jsf/getVendorPhone']
            },
            set(val) {
                this.$store.commit('Jsf/SET_VENDORPHONE', val)
            },
        },
        artapprovalname: {
            get() {
                return this.$store.getters['Jsf/getArtApprovalName']
            },
            set(val) {
                this.$store.commit('Jsf/SET_ARTAPPROVALNAME', val)
            },
        },
        artapprovalemail: {
            get() {
                return this.$store.getters['Jsf/getArtApprovalEmail']
            },
            set(val) {
                this.$store.commit('Jsf/SET_ARTAPPROVALEMAIL', val)
            },
        },
        notes: {
            get() {
                return this.$store.getters['Jsf/getNotes']
            },
            set(val) {
                this.$store.commit('Jsf/SET_NOTES', val)
            },
        },
        doorsconfirmed: {
            get() {
                return this.$store.getters['Jsf/getDoorsConfirmed']
            },
            set(val) {
                this.$store.commit('Jsf/SET_DOORSCONFIRMED', val)
            },
        },
        rush: {
            get() {
                return this.$store.getters['Jsf/getRush']
            },
            set(val) {
                this.$store.commit('Jsf/SET_RUSH', val)
            },
        },
        status: {
            get() {
                return this.$store.getters['Jsf/getStatus']
            },
            set(val) {
                this.$store.commit('Jsf/SET_STATUS', val)
            },
        },
        havepande: {
            get() {
                return this.$store.getters['Jsf/getPande']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDE', val)
            },
        },
        havepandepm4only: {
            get() {
                return this.$store.getters['Jsf/getPandePM4Only']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDEPM4ONLY', val)
            },
        },
        pandeitems: {
            get() {
                return this.$store.getters['Jsf/getPandeitems']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDEITEMS', val)
            },
        },
        pandecount: {
            get() {
                return this.$store.getters['Jsf/getPandecount']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDECOUNT', val)
            },
        },
        pandecountpm4only: {
            get() {
                return this.$store.getters['Jsf/getPandecountPM4Only']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDECOUNTPM4ONLY', val)
            },
        },
        pandestores: {
            get() {
                return this.$store.getters['Jsf/getPandestores']
            },
            set(val) {
                this.$store.commit('Jsf/SET_PANDESTORES', val)
            },
        },
        lineitems: {
            get() {
                return this.$store.getters['Jsf/getLineItems']
            },
            set(val) {
                this.$store.commit('Jsf/SET_LINEITEMS', val)
            },
        },
        partlist: {
            get() {
                return this.$store.getters['Sgs/getPartList']
            },
            set(val) {
                this.$store.commit('Sgs/SET_PARTLIST', val)
            },
        },
        islocked: {
            get() {
                return this.$store.getters['Jsf/getIsLocked']
            },
        },
        fixedkit: {
            get() {
                return this.$store.getters['Jsf/getFixedKit']
            },
        },
        depmiclist: {
            get() {
                return this.$store.getters['Contact/getDmList']
            },
            set(val) {
                this.$store.commit('Contact/SET_DMLIST', val)
            },
        },
        sgdblist: {
            get() {
                return this.$store.getters['Contact/getSgList']
            },
            set(val) {
                this.$store.commit('Contact/SET_SGLIST', val)
            },
        },
        matchstores: {
            get() {
                return this.$store.getters['Contact/getMatchStores']
            },
            set(val) {
                this.$store.commit('Contact/SET_MATCHSTORES', val)
            },
        },
    },
    methods: {
        addRow(fixedadder) {
            /* --- keep zero index --- */
            let nextidx = this.lineitems.length - 1 + 1,
                itemid = ''

            this.lineitems.push({
                idx: nextidx,
                item: itemid,
                perdoor: '',
                fixed_addition: fixedadder,
                quantity: '',
                total: '',
                name_applied: '',
            })
        },
        localRRow(idx) {
            this.removeRow([idx, this.lineitems]).then(newlist => {
                this.lineitems = newlist
                this.checkRequired('moddingli')
            })
        },
        async removeRow(vals) {
            let newlist = [],
                iter = 0

            /* --- must rework idx for refresh to work --- */
            for (let lidx = 0; lidx < vals[1].length; lidx++) {
                if (lidx !== vals[0] * 1) {
                    newlist.push({
                        id: vals[1][lidx].id * 1,
                        idx: iter,
                        item: vals[1][lidx].item,
                        perdoor: vals[1][lidx].perdoor,
                        quantity: vals[1][lidx].quantity,
                        fixed_addition: vals[1][lidx].fixed_addition,
                        total: vals[1][lidx].total,
                        name_applied: vals[1][lidx].name_applied,
                    })

                    iter++
                }
            }

            return newlist
        },
        setupProgs() {
            this.dmempty = true
            this.$store.commit('Jsf/RESET_LINEITEMS')

            if (this.dep && this.mic) {
                this.$store
                    .dispatch('Jsf/fetchProgsAndStores', null, { root: true })
                    .then(() => {
                        if (this.$store.getters['Jsf/getSelectedStores'].length) {
                            this.dmempty = false
                        }
                    })
                    .catch(() => {
                        this.dmempty = true
                    })
            }
        },
        checkRequired(e) {
            let missing = [],
                setready = false

            const req = [
                { field: 'dep', title: 'Department' },
                { field: 'mic', title: 'MIC' },
                { field: 'program', title: 'Promotion Type' },
                { field: 'title', title: 'Title' },
                { field: 'visualdate', title: 'Visual Date' },
                { field: 'doorsconfirmed', title: 'Doors Confirmed' },
            ]

            for (let idx = 0; idx < req.length; idx++) {
                if (!this[req[idx].field]) {
                    missing.push(req[idx].title)
                }
            }

            if (!this.lineitems.length) {
                this.$store.commit('Sgs/SET_MSG', [
                    true,
                    'Please ensure there is at least one line item.',
                    'red',
                    '-1',
                    'c',
                ])
                this.readyforsave = false
            } else {
                for (let liidx = 0; liidx < this.lineitems.length; liidx++) {
                    if (this.lineitems[liidx].perdoor < 1) {
                        this.$store.commit('Sgs/SET_MSG', [
                            true,
                            'A line item is missing a value.  Please check.',
                            'red',
                            '-1',
                            'c',
                        ])
                        this.readyforsave = false
                        setready = true

                        break
                    }
                }

                if (!setready) {
                    if (
                        !this.dep ||
                        !this.mic ||
                        !this.program ||
                        !this.title ||
                        !this.visualdate ||
                        !this.doorsconfirmed
                    ) {
                        this.$store.commit('Sgs/SET_MSG', [
                            true,
                            'Required values missing for ' + missing.join(', '),
                            'red',
                            '-1',
                            'c',
                        ])
                        //console.log("NOT READY FOR SAVE")
                        this.readyforsave = false
                    } else {
                        if (e === 'rush') {
                            this.$store.commit('Sgs/SET_MSG', [
                                true,
                                'Be sure to contact an administrator regarding the rush nature of this request.',
                                'red',
                                '-1',
                                'c',
                            ])
                            this.$store.dispatch('Jsf/saveJsf', 'Rush', { root: true }).then(() => {
                                this.$store.dispatch(
                                    'Jsf/setLock',
                                    { path: 'Jsf/Lock/' + this.jsfid.split('-')[1] },
                                    { root: true }
                                )
                            })
                        }

                        if (e !== 'moddingli') {
                            this.readyforsave = true
                        }
                    }
                }
            }

            if (!this.readyforsave) {
                this.$nextTick(() => {
                    this.savetype = ''
                })
            }
        },
        saveRushJsf() {
            this.checkRequired('rush')
        },
        unlockRush() {
            if (this.user.isadmin) {
                this.$store
                    .dispatch('Jsf/setLock', { path: 'Jsf/Unlock/' + this.jsfid.split('-')[1] }, { root: true })
                    .then(() => {
                        this.savetype = 'Pending Approval'
                        this.saveJsf()
                    })
            }
        },
        checkSaveJsf() {
            let status = this.savetypelistxref[this.savetypelist.indexOf(this.savetype)]

            if (status === 'Approved') {
                this.asklock = true
            } else {
                this.saveJsf(true)
            }
        },
        saveJsf(oktosave) {
            if (oktosave) {
                /* --- lazy, repeating --- */
                let status = this.savetypelistxref[this.savetypelist.indexOf(this.savetype)]

                this.$store.dispatch('Jsf/saveJsf', status, { root: true }).then(() => {
                    if (status === 'Approved') {
                        /* --- this throws an error, but works, debug later  2022-05-24 cal --- */
                        let path = 'Jsf/Lock/' + this.$store.getters['Jsf/getId']
                        this.$store.dispatch('Jsf/setLock', { path: path }, { root: true })
                    }
                })
            }
        },
        setPande() {
            if (this.pandecount * 1 > 0 && (this.program === 'PW4' || this.program === 'PW4-FOS')) {
                this.havepande = true
            } else {
                this.havepande = false
            }
        },
        fetchLis() {
            /* --- need specific properties to set, so just empty --- */
            this.$store.commit('Jsf/RESET_LINEITEMS')

            if (this.visualdate.length) {
                this.calcDates(false)
            }

            this.setPande()

            this.$store
                .dispatch('Jsf/fetchLis', null, { root: true })
                .then(() => {
                    this.dmempty = false
                })
                .catch(() => {
                    this.dmempty = true
                })
        },
        modLiTot(idx) {
            this.lineitems[idx].total = this.selectedstores.length * this.lineitems[idx].perdoor
            this.checkRequired('moddingli')
        },
        clearSelectedStores() {
            this.storegroup = 'None'

            this.$store.commit('Jsf/RESET_STORELIST')
            this.selectedstores = []
        },
        selectAllStores() {
            this.storegroup = 'None'

            this.$store.commit('Jsf/RESET_STORELIST')
            this.$store.commit('Jsf/SET_SELECTEDSTORESALL')
        },
        resetSelectedStores() {
            this.storegroup = 'None'

            this.$store.commit('Jsf/RESET_STORELIST')
            this.$store.commit('Jsf/SET_SELECTEDSTORES', this.$store.getters['Jsf/getBaseStoreList'])
        },
        selectStoreGroup(v) {
            this.$store.commit('Jsf/RESET_STORELIST')

            switch (v) {
                case 'edge':
                case 'pe':
                    this.$store.commit('Jsf/SET_SELECTEDSTORESGROUP', v)
                    break
                case 'None':
                case 'Random':
                    /* --- noop --- */
                    break
                default:
                    this.$store.dispatch('Contact/fetchStoreListByName', v, { root: true }).then(() => {
                        this.$store.commit('Jsf/SET_SELECTEDSTORESGROUPBYNAME', this.matchstores)
                    })
                    break
            }
        },
        calcDates(fromform) {
            let isfos = false,
                usedate = this.visualdate

            if (fromform) {
                usedate = this.pickerdate
            }

            if (this.program.indexOf('FOS') > -1) {
                isfos = true
            }

            this.$store.dispatch('Jsf/fetchDates', { vd: usedate, isfos: isfos }, { root: true })
            //this.instoredate = new Date().toLocaleDateString()
            //this.artdue = new Date().toLocaleDateString()
            //this.artapproval = new Date().toLocaleDateString()
            //this.shipdate = new Date().toLocaleDateString()

            /*
            console.log('JSF FOR HOLD STATUS')
            console.log(this.jsf)

            if (this.jsf.holdstatus === '1') {
                let sendinfo = {
                    metakey: 'holdstatus',
                    jsfnumber: this.jsfid
                }

                sendinfo.metavalue = false

                this.$store.dispatch('Jsf/modJsfMeta', sendinfo, { root: true })
            }
            */
        },
        confirmstores() {
            this.showstores = false
            this.doorsconfirmed = true

            this.pandecount = 0

            for (let idx = 0; idx < this.selectedstores.length; idx++) {
                if (this.pandestores.indexOf(this.selectedstores[idx]) > 0) {
                    this.pandecount++
                }
            }

            this.setPande()
        },
        selectrandom() {
            let worklist = this.storelist

            for (let i = worklist.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i)
                const temp = worklist[i]
                worklist[i] = worklist[j]
                worklist[j] = temp
            }

            this.$store.commit('Jsf/SET_SELECTEDSTORESRANDOM', this.randomstores)
        },
        getJsfDM(e) {
            const dmparts = e.split('-')
            this.dep = dmparts[0]
            this.mic = dmparts[1]

            this.setupProgs()
        },
        checkNotInList(item, fromselect) {
            console.log('CHECKING')
            console.log(item)

            this.edititem = item

            if (fromselect) {
                if (item.id === 55) {
                    this.asknol = true
                    this.editnametitle = 'Please Name this Item'
                    this.newitemname = ''
                }
            } else {
                if (item.name_applied) {
                    this.newitemname = item.name_applied
                } else {
                    this.newitemname = item.item
                }

                this.asknol = true
                this.editnametitle = 'Change the Applied Name'
            }
        },
        processNewItemName(dowhat) {
            /* --- item id 55 = Not In List --- */
            let moveon = false

            console.log('ITEM UNDER EDIT')
            console.log(this.edititem)

            if ((!this.newitemname.length || !this.newitemname.replace(/\s/g, '').length) && this.edititem.id === 55) {
                this.edititemmsg = 'A new name is required for Not In List items.'
            } else {
                moveon = true
            }

            switch (dowhat) {
                case 'save':
                    if (moveon) {
                        this.edititem.name_applied = this.newitemname
                        this.edititemmsg = ''
                        this.asknol = false
                    }
                    break
                case 'cancel':
                    if (moveon) {
                        this.edititemmsg = ''
                        this.asknol = false
                    }
                    break
                case 'original':
                    this.edititem.name_applied = null
                    this.asknol = false
                    break
            }
        },
    },
}
